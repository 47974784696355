import { casoService, neDestinoService } from '../_services';
import { router } from '../_helpers';

let service = casoService;
let serviceNEDestino = neDestinoService;

const state = {
    thumbnails: [],
    docThumbnails: [],
    file: {},
    filePath: {},
    items: [],
    all: {},
    item: {},
    itemCompact: {},
    itemResult: null,
    emails: {},
    tramite: {},
    marcaModelo: '',
    id: 0,
    casosFinalizados: false,
    usuarioActual: false,
    modificado: false,
    progress: false,
    dialog: false,
    borraDialog: false,
    dialogTramite: false,
    todosTramitesCompletados: false,
    showImages: true,
    deflt: {
        id: 0,
        marcaModelo: '',
        nombre: '',
        borrado: false,
        applicationUserId: null,
        applicationUser: { username: '', id: null, email: '' },
        clienteId: null,
        cliente: { nombre: '', id: null },
        siniestro: '',
        fechaSiniestro: null,
        estadoId: 12, //ESTADOS.SIN_INICIAR,
        estado: { nombre: 'SIN INICIAR', id: 12, textColor: "" },
        marcaId: null,
        marca: { nombre: '', id: null },
        modeloValorId: null,
        modeloValor: { marcaId: null, marca: '', modeloId: null, modelo: '', tipoId: null, tipo: '' },
        modeloId: null,
        modelo: { nombre: '', id: null },
        anio: null,
        tramitadorId: null,
        tramitador: { nombre: '', id: null },
        desarmaderoId: null,
        desarmadero: { nombre: '', id: null, vencimientoRUDAC: null },
        gestorId: null,
        gestor: { nombre: '', id: null },
        productorId: null,
        productor: { nombre: '', id: null },
        registroId: null,
        registro: { nombre: '', id: null },
        tipoBajaId: 1,
        tipoBaja: { nombre: '04d con desarmadero', id: 1 },
        tipoVehiculoId: 1,
        tipoVehiculo: { nombre: 'Auto', id: 1 },
        titularId: null,
        titular: { nombre: '', id: null },
        estadoInformeId: null,
        estadoInforme: { nombre: '', id: null, fullName: '' },
        deudaMulta: null,
        deudaPatente: null,
        casoTramite: [],
        casoAlerta: [],
        alertaLLamado: '',
        notificacion: []
    },

    defltTramite: {
        id: 0,
        orden: 0,
        borrado: false,
        nuevoTramite: false,
        tramiteId: 0,
        descripcion: '',
        fInicio: null,
        fCierre: null,
        completado: false,
        usuarioId: 0,
        numeroComprobante: '',
        fechaComprobante: null,
        importeComprobante: null,
        notificacion: []
    },

    defltEmails: [
        { "id": 1, "nombre": "Genérico", "Email": null },
        { "id": 2, "nombre": "Compañía", "Email": null },
        { "id": 3, "nombre": "Titular", "Email": null },
        { "id": 4, "nombre": "Productor", "Email": null },
        { "id": 5, "nombre": "Tramitador", "Email": null },
        { "id": 6, "nombre": "Usuario", "Email": null },
        { "id": 7, "nombre": "Gestor", "Email": null },
        { "id": 8, "nombre": "Desarmadero", "Email": null }
    ],

};

const getters = {
    getAlerta: (state, getters, rootState) => (alertaId) => {
        let ret = state.item.casoAlerta.find(x => x.alertaId == alertaId);
        if (ret) {
            return ret;
        }
        let dt = new Date();
        return {
            alertaId: alertaId,
            applicationUserId: rootState.account.loggedUser.id,
            fInicio: dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + 'T00:00:00',
            fCierre: null,
            descripcion: ''
        };
    }
};

const actions = {
    async initialize({ dispatch, commit }) {
        await dispatch('desarmadero/getAllAsync', null, { root: true });
        await dispatch('estado/getAllAsync', null, { root: true });
        dispatch('modelovalortipo/getAll', null, { root: true });
        dispatch('desarmadero/getAllTVehiculoTBaja', null, { root: true });
        dispatch('tramite/getAll', null, { root: true });
        dispatch('marca/getAll', null, { root: true });
        dispatch('modelo/getAll', null, { root: true });
        dispatch('tipobaja/getAll', null, { root: true });
        dispatch('cliente/getAll', null, { root: true });
        dispatch('tramitador/getAll', null, { root: true });
        dispatch('campotramite/getAll', null, { root: true });
        commit('setItem', JSON.parse(JSON.stringify(state.deflt)));
        var cf = localStorage.getItem('casosFinalizados');

        if (cf == null || cf == "false") {
            cf = false
        }
        else {
            cf = true;
        }

        commit('setCasosFinalizados', cf);

        var bc = localStorage.getItem('usuarioActual');

        if (bc == null || bc == "false") {
            bc = false
        }
        else {
            bc = true;
        }
        commit('setUsuarioActual', bc);
    },

    initializeDirect({ dispatch, commit }) {
        dispatch('estado/getAll', null, { root: true });

        dispatch('modelovalortipo/getAll', null, { root: true });
        dispatch('desarmadero/getAllTVehiculoTBaja', null, { root: true });
        dispatch('tramite/getAll', null, { root: true });
        dispatch('tramitador/getAll', null, { root: true });
        commit('setItem', JSON.parse(JSON.stringify(state.deflt)));
        var cf = localStorage.getItem('casosFinalizados');

        if (cf == null || cf == "false") {
            cf = false
        }
        else {
            cf = true;
        }

        commit('setCasosFinalizados', cf);
        dispatch('getCompact');

        var bc = localStorage.getItem('usuarioActual');

        if (bc == null || bc == "true") {
            bc = true
        }
        else {
            bc = false;
        }

        commit('setUsuarioActual', bc);
    },

    initializePaging({ dispatch, commit }, payLoad) {
        dispatch('tipobaja/getAll', null, { root: true });
        dispatch('getCompactPaging', payLoad);
        commit('setItem', JSON.parse(JSON.stringify(state.deflt)));
    },

    editItem({ dispatch, commit, state, rootState }, itm) {
        if (state.dialog) {
            commit('setDialog', false);
            commit('setshowImages', false);
        }

        if (itm === null) {
            commit('setItem', JSON.parse(JSON.stringify(state.deflt)));
            state.item.applicationUserId = rootState.account.loggedUser.id;
            state.item.applicationUser = {
                id: rootState.account.loggedUser.id,
                userName: rootState.account.loggedUser.userName,
                email: rootState.account.loggedUser.email
            };

        } else {
            dispatch('getById', itm.id);
            dispatch('getThumbnails', { system: 0, domain: itm.nombre, subdomain: itm.siniestro, folder: 'fotos' });
            dispatch('getDocThumbnails', { system: 0, domain: itm.nombre, subdomain: itm.siniestro, folder: 'documentoscia' });
        }
        commit('setDialog', true);
        commit('setshowImages', true);
    },

    editItemDominioSiniestro({ dispatch, commit, state, rootState }, itm) {
        if (state.dialog) {
            commit('setDialog', false);
            commit('setshowImages', false);
        }

        if (itm === null) {
            commit('setItem', JSON.parse(JSON.stringify(state.deflt)));
            state.item.applicationUserId = rootState.account.loggedUser.id;
            state.item.applicationUser = {
                id: rootState.account.loggedUser.id,
                userName: rootState.account.loggedUser.userName,
                email: rootState.account.loggedUser.email
            };

        } else {
            dispatch('getByDominioSiniestro', itm);
        }
    },


    borraItem({ commit }, itm) {
        commit('setItem', itm);
        commit('setBorraDialog', true);
    },

    editTramite({ dispatch, commit }, itm) {
        if (this.state.account.permisos.casosCliente) {
            itm.nuevoTramite = false;
            dispatch('setTramite', itm);
            commit('setDialogTramite', true);
        }
    },

    newTramite({ dispatch, commit }, tramiteId) {
        var tmt = state.item.casoTramite.find(x => x.tramiteId == tramiteId);
        if (tramiteId >= this.state.TRAMITES.INICIABAJA && tmt != null && tmt != undefined) {
            dispatch('editTramite', tmt);
        } else {
            var nt = JSON.parse(JSON.stringify(state.defltTramite));
            nt.orden = state.item.casoTramite.length;
            nt.borrado = false;
            nt.nuevoTramite = true;
            nt.tramiteId = tramiteId;
            nt.fInicio = null;
            nt.fCierre = null;
            nt.completado = false;
            nt.usuario = 0;
            dispatch('setTramite', nt);
            commit('setDialogTramite', true);
        }
    },

    tramitesCompletados() {
        let self = this;
        state.todosTramitesCompletados =
            state.item.casoTramite.find(
                x =>
                    x.completado == false &&
                    x.tramiteId >= self.state.TRAMITES.INICIABAJA &&
                    self.state.tramite.items.find(l => l.id == x.tramiteId).modulo & 1 == 1
            ) == null;
    },


    getAll({ dispatch, commit }) {
        commit('getAllRequest');

        service.getAll(this.state.account.loggedUser.userClientes).then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    getFile({ commit, state }, params) {
        //commit('getFileRequest')
        service.getFile({
            "domain": state.item.nombre,
            "subDomain": state.item.siniestro,
            "folder": params.folder,
            "system": "0"
        }, params.file).then(
            registro => commit('getFileSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    getFile({ commit, state }, params) {
        //commit('getFileRequest')
        service.getFile({
            "domain": state.item.nombre,
            "subDomain": state.item.siniestro,
            "folder": params.folder,
            "system": "0"
        }, params.file).then(
            registro => commit('getFileSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    getFilePath({ commit, state }, params) {
        var endpoint = service.getAPIServer();

        commit('getFilePathRequest', {
            "endpoint": endpoint,
            "domain": state.item.nombre,
            "subDomain": state.item.siniestro,
            "folder": params.folder,
            "file": params.file,
            "system": "bajas"
        });
    },

    getThumbnails({ dispatch, commit, state }, payload) {
        commit('getThumbnailsRequest');

        if (payload.nombre != "" && payload.siniestro != "") {
            service.getThumbnails(payload).then(
                registro => commit('getThumbnailsSuccess', registro.data.entries),
                error => {
                    commit('failure', error);
                    if (error.response.status == 401) {
                        dispatch(
                            'alert/errorTime',
                            'Error de permisos o sesión expirada!',
                            {
                                root: true
                            }
                        );
                        router.push({ name: 'login' });
                    } else {
                        dispatch('alert/errorTime', error.message, {
                            root: true
                        });
                    }
                }
            );
        }
    },

    getDocThumbnails({ dispatch, commit, state }, payload) {
        commit('getDocThumbnailsRequest');

        if (payload.nombre != "" && payload.siniestro != "") {
            service.getThumbnails(payload).then(
                registro => commit('getDocThumbnailsSuccess', registro.data.entries),
                error => {
                    commit('failure', error);
                    if (error.response.status == 401) {
                        dispatch(
                            'alert/errorTime',
                            'Error de permisos o sesión expirada!',
                            {
                                root: true
                            }
                        );
                        router.push({ name: 'login' });
                    } else {
                        dispatch('alert/errorTime', error.message, {
                            root: true
                        });
                    }
                }
            );
        }
    },

    getCompact({ dispatch, commit, state, rootState }) {
        var self = this;
        commit('getAllRequest');
        if (state.usuarioActual == false) {

            return service.getCompact({
                clientes: rootState.account.loggedUser.userClientes,
                finalizados: state.casosFinalizados,
                applicationUserId: rootState.account.loggedUser.id,
                itemsPerPage: 0,
                page: 0
            }).then(
                registro => commit('getCompactSuccess', { registro, self }),
                error => {
                    commit('failure', error);
                    if (error.response.status == 401) {
                        dispatch(
                            'alert/errorTime',
                            'Error de permisos o sesión expirada!',
                            {
                                root: true
                            }
                        );
                        router.push({ name: 'login' });
                    } else {
                        dispatch('alert/errorTime', error.message, {
                            root: true
                        });
                    }
                }
            );
        } else {
            return service.getUserCompact({
                clientes: rootState.account.loggedUser.userClientes,
                finalizados: state.casosFinalizados,
                applicationUserId: rootState.account.loggedUser.id,
                itemsPerPage: 0,
                page: 0
            }).then(
                registro => commit('getCompactSuccess', { registro, self }),
                error => {
                    commit('failure', error);
                    if (error.response.status == 401) {
                        dispatch(
                            'alert/errorTime',
                            'Error de permisos o sesión expirada!',
                            {
                                root: true
                            }
                        );
                        router.push({ name: 'login' });
                    } else {
                        dispatch('alert/errorTime', error.message, {
                            root: true
                        });
                    }
                }
            );

        }
    },

    async getCompactAsync({ dispatch, commit, state, rootState }) {
        var self = this;
        commit('getAllRequest');
        if (state.usuarioActual == false) {
            try {
                commit('getCompactSuccess', await service.getCompactAsync({
                    clientes: rootState.account.loggedUser.userClientes,
                    finalizados: state.casosFinalizados,
                    applicationUserId: rootState.account.loggedUser.id,
                    itemsPerPage: 0,
                    page: 0
                }));
            }
            catch (error) {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        } else {
            return service.getAlertCompact({
                clientes: rootState.account.loggedUser.userClientes,
                finalizados: state.casosFinalizados,
                applicationUserId: rootState.account.loggedUser.id,
                itemsPerPage: 0,
                page: 0
            }).then(
                registro => commit('getCompactSuccess', { registro, self }),
                error => {
                    commit('failure', error);
                    if (error.response.status == 401) {
                        dispatch(
                            'alert/errorTime',
                            'Error de permisos o sesión expirada!',
                            {
                                root: true
                            }
                        );
                        router.push({ name: 'login' });
                    } else {
                        dispatch('alert/errorTime', error.message, {
                            root: true
                        });
                    }
                }
            );

        }
    },

    getCompactPaging({ dispatch, commit, rootState }, payLoad) {
        var self = this;

        commit('getAllRequest');
        payLoad.clientes = rootState.account.loggedUser.userClientes;
        service.getCompactPaging(payLoad).then(
            registro => commit('getCompactPagingSuccess', { registro, self }),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    getById({ dispatch, commit }, id) {
        var self = this;

        commit('getByRequest');

        service.getById(id).then(
            registro => {
                // Filtra observaciones internas si es compañia o productor
                registro.casoTramite = registro.casoTramite.filter(
                    x =>
                        // Tramite contiene Modulo Cliente y es una compañia
                        self.state.account.loggedUser.userRoles.find(t => t == 6) == 6 && ((x.tramite.modulo & 4) == 4) &&
                        (
                            ((x.tramite.modulo & 1) == 1 && self.state.account.loggedUser.grants.indexOf('0100') >= 0) ||
                            ((x.tramite.modulo & 2) == 2 && self.state.account.loggedUser.grants.indexOf('0600') >= 0) ||
                            ((x.tramite.modulo & 8) == 8 && self.state.account.loggedUser.grants.indexOf('0700') >= 0)
                        ) ||
                        // Tramite contiene Modulo Cliente y no es una compañia
                        self.state.account.loggedUser.userRoles.find(t => t == 6) === undefined && ((x.tramite.modulo & 4) == 4) &&
                        (
                            ((x.tramite.modulo & 1) == 1 && self.state.account.loggedUser.grants.indexOf('0100') >= 0) ||
                            ((x.tramite.modulo & 2) == 2 && self.state.account.loggedUser.grants.indexOf('0600') >= 0) ||
                            ((x.tramite.modulo & 8) == 8 && self.state.account.loggedUser.grants.indexOf('0700') >= 0)
                        ) ||
                        // Tramite no contiene Modulo Cliente y no es una compañia
                        self.state.account.loggedUser.userRoles.find(t => t == 6) === undefined && ((x.tramite.modulo & 4) == 0) &&
                        (
                            ((x.tramite.modulo & 1) == 1 && self.state.account.loggedUser.grants.indexOf('0100') >= 0) ||
                            ((x.tramite.modulo & 2) == 2 && self.state.account.loggedUser.grants.indexOf('0600') >= 0) ||
                            ((x.tramite.modulo & 8) == 8 && self.state.account.loggedUser.grants.indexOf('0700') >= 0)
                        )
                    // Tramite no contiene Modulo Cliente y es una compañia (lo filtra)
                );

                registro.casoTramite.map((val, index) => (val.orden = index));
                dispatch('titular/getById', registro.titularId, { root: true });

                commit('getBySuccess', registro);

                // Completa orden
                //service.orderTramites(state.item);
                dispatch('setState');
                dispatch('getEmails');
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    getByGuid({ dispatch, commit, rootState }, guid) {
        var self = this;

        commit('getByRequest');

        service.getByGuid(guid).then(
            registro => {
                // Filtra observaciones internas si es compañia o productor
                registro.casoTramite = registro.casoTramite.filter(
                    x =>
                        (x.tramite.modulo & 4) == 4 ||   // Modulo Cliente
                        ((x.tramite.modulo & 1) == 1 &&
                            self.state.account.loggedUser.grants.indexOf('0100') >= 0) ||   // 
                        ((x.tramite.modulo & 2) == 2 &&
                            self.state.account.loggedUser.grants.indexOf('0600') >= 0) ||   // 
                        ((x.tramite.modulo & 8) == 8 &&
                            self.state.account.loggedUser.grants.indexOf('0700') >= 0)    // 
                );

                if (!rootState.account.loggedUser.loggedIn) {
                    registro.applicationUserId = null;
                    registro.applicationUser = { id: null, userName: "", email: '' };
                }
                else {
                    registro.applicationUserId = rootState.account.loggedUser.id;
                    registro.applicationUser = {
                        id: rootState.account.loggedUser.id,
                        userName: rootState.account.loggedUser.userName,
                        email: rootState.account.loggedUser.email
                    };
                }

                registro.casoTramite.map((val, index) => (val.orden = index));

                commit('getBySuccess', registro);
                // Completa orden
                //service.orderTramites(state.item);
                dispatch('setState');
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    getByDominioSiniestro({ dispatch, commit, rootState }, ds) {
        var self = this;

        commit('getByRequest');

        service.getByDominioSiniestro(ds).then(
            registro => {
                // Filtra observaciones internas si es compañia o productor
                registro.casoTramite = registro.casoTramite.filter(
                    x =>
                        (x.tramite.modulo & 4) == 4 ||   // Modulo Cliente
                        ((x.tramite.modulo & 1) == 1 &&
                            self.state.account.loggedUser.grants.indexOf('0100') >= 0) ||   // 
                        ((x.tramite.modulo & 2) == 2 &&
                            self.state.account.loggedUser.grants.indexOf('0600') >= 0) ||   // 
                        ((x.tramite.modulo & 8) == 8 &&
                            self.state.account.loggedUser.grants.indexOf('0700') >= 0)    // 
                );

                if (!rootState.account.loggedUser.loggedIn) {
                    registro.applicationUserId = null;
                    registro.applicationUser = { id: null, userName: "", email: '' };
                }
                else {
                    registro.applicationUserId = rootState.account.loggedUser.id;
                    registro.applicationUser = {
                        id: rootState.account.loggedUser.id,
                        userName: rootState.account.loggedUser.userName,
                        email: rootState.account.loggedUser.email
                    };
                }

                registro.casoTramite.map((val, index) => (val.orden = index));

                commit('getBySuccess', registro);
                commit('setDialog', true);
                commit('setshowImages', true);

                // Completa orden
                //service.orderTramites(state.item);
                dispatch('setState');
            },
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else if (error.response.status == 404) {
                    dispatch('alert/errorTime', "No existe dominio o siniestro!", {
                        root: true
                    });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    getEmails({ commit, state }) {
        commit('getEmailsRequest');

        let neEmailsRequest = [
            { "id": 1, "neDestinoId": state.item.cliente.id },
            { "id": 2, "neDestinoId": state.item.cliente.id },
            { "id": 3, "neDestinoId": state.item.titular.id },
            { "id": 4, "neDestinoId": state.item.productor.id },
            { "id": 5, "neDestinoId": state.item.tramitador.id },
            { "id": 6, "neDestinoId": state.item.applicationUser.id },
            { "id": 7, "neDestinoId": state.item.gestor.id },
            { "id": 8, "neDestinoId": state.item.desarmadero.id }
        ];

        serviceNEDestino.getEmails(neEmailsRequest).then(
            registro => commit('getEmailsSuccess', registro),
            error => commit('failure', error)
        );
    },

    getModeloValor({ commit, dispatch }, id) {
        return new Promise(() => {

            commit('setModeloValorRequest');

            service.getModeloValor(id).then(
                registro => {
                    commit('setModeloValorSuccess', registro);
                },
                error => {
                    commit('failure', error);
                    if (error.response.status == 401) {
                        dispatch(
                            'alert/errorTime',
                            'Error de permisos o sesión expirada!',
                            {
                                root: true
                            }
                        );
                        router.push({ name: 'login' });
                    } else {
                        dispatch('alert/errorTime', error.message, {
                            root: true
                        });
                    }
                }
            );
        });
    },

    setModificado({ state }, val) {
        state.modificado = val;
    },

    setState({ state, rootGetters }) {
        var tramites = service.orderTramites(state.item);
        var self = this;
        if (this._modules.root.state.account.loggedUser.loggedIn) {
            // Si hay mas anulados que reactivados pone en estado anulado
            if (service.anulado(tramites) != 0) {
                state.item.estadoId = 2; // Anulado
                state.item.estado = self.state.estado.all.items[1];
            } else {
                // busca el estado filtrando observaciones, anulados y reactivados y
                // tramites que no tengas estados inicial y final indeterminados,
                // si no hay tramites pone sininiciar
                var tmtes = tramites.filter(x => {
                    var tm = rootGetters['tramite/getTramiById'](x.tramiteId);
                    if (tm == undefined)
                        return false;
                    return (tm.estadoInicialId > 2 || tm.estadoFinalId > 2) && (tm.modulo & 1 == 1);
                });

                //var t = rootGetters["tramite/getTramiById"](1);
                //t = rootGetters["tramite/getTramiById"](1001);
                var tmt = 0;

                // Si no hay tramites asigna TRAMITES.SIN_INICIAR
                if (tmtes.length == 0) {
                    state.item.estadoId = self.state.ESTADOS.SIN_INICIAR;
                    state.item.estado = self.state.estado.all.items.find(
                        x => x.id == self.state.ESTADOS.SIN_INICIAR
                    );
                } else {
                    tmtes.map(function (val) {
                        if (tmt == 0) {
                            //if (tmt < val.tramiteId) {
                            tmt = val.tramiteId;
                            if (!val.completado) {
                                var tr = self.state.tramite.items.find(
                                    x => x.id == tmt
                                );
                                state.item.estadoId = tr.estadoInicialId;
                                state.item.estado = tr.estadoInicial;
                            } else {
                                var tr1 = self.state.tramite.items.find(
                                    x => x.id == tmt
                                );
                                state.item.estadoId = tr1.estadoFinalId;
                                state.item.estado = tr1.estadoFinal;
                            }
                        }
                    });
                }
            }
        }
    },

    //getxyyyy({ commit }, yyyyId) {
    //    commit('getAllRequest');

    //    service
    //        .getxyyyy(yyyyId)
    //        .then(
    //            registro => commit('getAllSuccess', registro),
    //            error => commit('failure', error)
    //        );
    //},

    saveItem({ dispatch, commit }) {
        if (state.item.id > 0) {
            dispatch('putObject');
        } else {
            dispatch('postObject');
        }
        commit('setDialog', false);
        commit('setshowImages', false);

    },

    setCasosFinalizados({ dispatch, commit }, payload) {
        commit('setCasosFinalizados', payload);
        dispatch('getCompact');
    },

    setUsuarioActual({ commit }, payload) {
        commit('setUsuarioActual', payload);
    },

    setDialog({ commit }, payload) {
        commit('setDialog', payload);
    },
    setshowImages({ commit }, payload) {
        commit('setshowImages', payload);
    },

    setBorraDialog({ commit }, payload) {
        commit('setBorraDialog', payload);
    },

    setDialogTramite({ commit }, payload) {
        commit('setDialogTramite', payload);
    },

    postObject({ dispatch, commit }) {
        commit('postRequest', state.item);

        state.item.applicationUserId = state.item.applicationUserId == null ? this._modules.root.state.account.loggedUser.id : state.item.applicationUserId;
        return service.postObject(state.item).then(
            registro => {
                commit('setItemCompact', registro);
                commit('postSuccess');
            },
            error => {
                commit('failure', error);
                state.error = true;
                dispatch('alert/error', error.response.data, {
                    root: true
                });
            }
        );
    },

    putObject({ dispatch, commit, state }) {
        commit('putRequest', state.item);

        state.item.applicationUserId = state.item.applicationUserId == null ? this._modules.root.state.account.loggedUser.id : state.item.applicationUserId;
        service.putObject(state.item).then(
            registro => {
                commit('setItemCompact', registro);
                commit('putSuccess');
            },
            error => {
                commit('failure', error);
                state.error = true;
                dispatch('alert/errorTime', error.response.data, {
                    root: true
                });
            }
        );
    },

    deleteObject({ dispatch, commit, state }, id) {
        commit('deleteRequest', id);

        service.deleteObject(id).then(
            () => commit('deleteSuccess', id),
            error => {
                commit('failure', error);
                state.error = true;
                dispatch('alert/errorTime', error.response.data, {
                    root: true
                });
            }
        );
        commit('setBorraDialog', false);
    },

    exportaBajas({ dispatch, commit, state }) {
        commit('exportaCasosRequest');

        service.casosExporta().then(
            response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'bajas.xls');
                document.body.appendChild(fileLink);

                fileLink.click();
                commit('exportaCasosSuccess');
            },
            error => {
                commit('failure', error);
                state.error = true;
                dispatch('alert/ errorTime', error.message, {
                    root: true
                });
            }
        );
    },

    closeDialog({ commit }) {
        commit('setDialog', false);
        commit('setshowImages', false);
    },

    closeBorraDialog({ commit }) {
        commit('setBorraDialog', false);
    },

    closeDialogTramite({ commit }) {
        commit('setDialogTramite', false);
    },

    sendNotificacion({ dispatch, commit, state }) {
        state.item.notificacion.forEach(item => {
            if (item.asunto != null) {
                item.asunto = item.asunto.replace("{yyyy}", state.item.nombre);
                item.asunto = item.asunto.replace("{xxxx}", state.item.siniestro);
            }
            if (item.mensaje != null) {
                item.mensaje = item.mensaje.replace("{yyyy}", state.item.nombre);
                item.mensaje = item.mensaje.replace("{xxxx}", state.item.siniestro);
            }
            if (item.direccionSoporte == "" || item.direccionSoporte == null) {
                let m = state.emails.find(x => x.id == item.neDestinoId);
                if (m !== null) {
                    item.direccionSoporte = m.email;
                }
                else {
                    item.direccionSoporte = "";
                }
            }

            item.emailOperador = state.item.applicationUser.email;
            dispatch('neNotificacion/setItem', item, { root: true });
            dispatch('neNotificacion/postObject', item, { root: true });

        });
        commit('clearNotificacionCaso');
    },


    setItem({ commit }, payload) {
        commit('setItem', payload);
    },

    setTramite({ dispatch, commit, state }, payload) {
        if (payload.notificacion != undefined)
            commit('setTramite', payload)
        else {
            let slf = this;
            dispatch('neDestino/getByCiaTramiteDestinoSoporteAsync', {
                cia: state.item.cliente.id,
                tramite: payload.tramiteId,
                destino: 0,
                soporte: 0
            }, { root: true })
                .then((registro) => {
                    registro.map(itm => {
                        let m = slf.state.caso.emails.find(x => x.id == itm.neDestinoId);
                        if (m !== null) {
                            itm.direccionSoporte = m.email;
                        }
                    });
                    payload.notificacion = registro;

                    commit('setTramite', payload)
                });
        }
    },

    setNotificacionTramite({ commit }, payload) {
        commit('setNotificacionTramite', payload);
    },

    setNotificacionCaso({ commit }, payload) {
        commit('setNotificacionCaso', payload);
    },

    pushChild({ commit }, payload) {
        commit('pushChild', payload);
    },

    setAlerta({ commit }, payload) {
        commit('setAlerta', payload);
    }
};

const mutations = {
    // GetAll
    getAllRequest(state) {
        state.all = { loading: true };
        state.progress = true;
    },

    getThumbnailsRequest(state) {
        state.thumbnails = [];
    },

    getDocThumbnailsRequest(state) {
        state.docThumbnails = [];
    },

    getFilePathRequest(state, payload) {
        payload.domain = payload.domain
            .replace(/[/\\]/g, '')  // Elimina '/' y '\'
            .replace(/\s+/g, '');   // Elimina espacios
        payload.subDomain = payload.subDomain
            .replace(/[/\\]/g, '')  // Elimina '/' y '\'
            .replace(/\s+/g, '');   // Elimina espacios

        state.filePath = payload.endpoint +"download-file/" + payload.system + "/" + payload.domain + "-" + payload.subDomain + "/" + payload.folder + "/" + payload.file;
    },

    getAllSuccess(state, registro) {
        registro.map(x => (x.alerta = x.casoAlerta.find(x => x.alertaId == 1 || x.alertaId == 2).alertaId));
        state.items = { items: registro };
        state.progress = false;
    },

    getThumbnailsSuccess(state, registro) {
        state.thumbnails = registro;
        state.progress = false;
    },

    getFileSuccess(state, registro) {
        state.file = registro;
        state.progress = false;
    },

    getDocThumbnailsSuccess(state, registro) {
        state.docThumbnails = registro;
        state.progress = false;
    },

    getCompactSuccess(state, payload) {
        let self = this;
        state.all = {
            items: payload.registro.map(function (item) {

                var rec = self.state.estado.all.items.find(x => x.id == item.estadoId);

                if (rec != undefined) {
                    item.estado = rec;
                } else {
                    item.estado = null;
                }

                var rec1 = self.state.desarmadero.items.find(x => x.id == item.desarmaderoId);
                if (rec1 != undefined) {
                    item.desarmadero = rec1;
                } else {
                    item.desarmadero = null;
                }

                return item;

            })
        };
        state.progress = false;
    },

    setRelation() {

    },

    getCompactBySuccess(state, item) {
        state.item = item;
        let self = this;

        var rec = self.state.estado.all.items.find(x => x.id == item.estadoId);

        if (rec != undefined) {
            item.estado = rec;
        } else {
            item.estado = null;
        }

        var rec1 = self.state.desarmadero.items.find(x => x.id == item.desarmaderoId);
        if (rec1 != undefined) {
            item.desarmadero = rec1;
        } else {
            item.desarmadero = null;
        }

        state.modificado = false;
        state.id = item.id;
        state.progress = false;
    },

    getCompactPagingSuccess(state, payload) {
        //payload.registro.map(x => (x.alerta = x.casoAlerta.find(x => x.alertaId == 1 || x.alertaId == 2).alertaId));
        state.all = { items: payload.registro };
        state.progress = false;
    },

    failure(state) {
        state.id = 0;
        state.progress = false;
    },

    // GetBy
    getByRequest(state) {
        state.item = JSON.parse(JSON.stringify(state.deflt));
        state.id = 0;
        state.progress = true;
    },

    getBySuccess(state, registro) {
        state.item = registro;
        if (registro.modeloValor == null || registro.modeloValor == '')
            state.marcaModelo = registro.marca.nombre + ' ' + registro.modelo.nombre;
        state.modificado = false;
        state.id = registro.id;
        state.progress = false;
    },

    // GetEmails
    getEmailsRequest(state) {
        state.progress = true;
    },

    getEmailsSuccess(state, registro) {
        state.emails = registro;
        state.progress = false;
    },

    // Post
    postRequest(state) {
        state.progress = true;
    },
    postSuccess(state) {
        state.all.items.push(state.itemCompact);
        state.progress = false;
    },

    // Put
    putRequest(state) {
        state.progress = true;
    },

    putSuccess(state) {
        var i = state.all.items.find(x => x.id == state.itemCompact.id);
        var index = state.all.items.indexOf(i);
        state.all.items.splice(index, 1, JSON.parse(JSON.stringify(state.itemCompact)));
        state.progress = false;
    },

    setItemCompact(state, item) {
        let self = this;

        var rec = self.state.estado.all.items.find(x => x.id == item.estadoId);

        if (rec != undefined) {
            item.estado = rec;
        } else {
            item.estado = null;
        }

        var rec1 = self.state.desarmadero.items.find(x => x.id == item.desarmaderoId);
        if (rec1 != undefined) {
            item.desarmadero = rec1;
        } else {
            item.desarmadero = null;
        }

        state.itemCompact = item;
    },

    deleteRequest(state, id) {
        state.progress = true;
        // add 'deleting:true' property to user being deleted
        state.all.items = state.all.items.map(user =>
            user.id === id ? { ...user, deleting: true } : user
        );
    },

    deleteSuccess(state, id) {
        // remove deleted user from state
        state.all.items = state.all.items.filter(user => user.id !== id);
        state.progress = false;
    },

    exportaCasosRequest(state) {
        state.progress = true;
    },

    exportaCasosSuccess(state) {
        state.progress = false;
    },

    // GetBy
    setModeloValorRequest(state) {
        state.item.modeloValor = JSON.parse(JSON.stringify(state.deflt)).modeloValor;
        state.progress = true;
    },

    setModeloValorSuccess(state, registro) {
        state.item.modeloValor = registro;
        //if (registro.modeloValor == null || registro.modeloValor == '')
        //    state.marcaModelo = registro.marca.nombre + ' ' + registro.modelo.nombre;
        state.progress = false;
    },

    setCasosFinalizados(state, payload) {
        state.casosFinalizados = payload;
        localStorage.setItem('casosFinalizados', payload);
    },

    setUsuarioActual(state, payload) {
        state.usuarioActual = payload;
        localStorage.setItem('usuarioActual', payload);
    },

    setDialog(state, payload) {
        state.dialog = payload;
        if (payload == false) {
            state.item = payload; // JSON.parse(JSON.stringify(state.deflt));
        }
    },

    setshowImages(state, payload) {
        state.showImages = payload;
    },

    setBorraDialog(state, payload) {
        state.borraDialog = payload;
    },

    setDialogTramite(state, payload) {
        state.dialogTramite = payload;
    },

    setItem(state, payload) {
        state.item = payload;
        if (payload.id == 0) {
            state.id = 0;
        }
    },

    setTramite(state, payload) {
        state.tramite = payload;
    },

    setNotificacionTramite(state, payload) {
        state.tramite.notificacion = JSON.parse(JSON.stringify(payload));
    },

    setNotificacionCaso(state, payload) {
        state.item.notificacion = JSON.parse(JSON.stringify(state.item.notificacion.filter(x => x.tramiteId != state.tramite.tramiteId)));
        payload.map((val) => {
            if (val.envia) {
                val.tramiteCaso = state.tramite.id;         // Agrega numero de tramite en el caso para usar en neNotificacion
                state.item.notificacion.push(val);
            }
        });
        state.item.notificacion = service.orderNotificaciones(state.item);
    },

    clearNotificacionCaso(state) {
        state.item.notificacion = [];
    },

    pushChild(state, payload) {
        state.item.casoTramite.push(payload);
    },

    setAlerta(state, payload) {
        var estado = state.item.casoAlerta.find(x => x.alertaId == payload.alertaId);

        if (payload.value) {
            if (!estado) {
                state.item.casoAlerta.push({
                    alertaId: payload.alertaId,
                    applicationUserId: this._modules.root.state.account.loggedUser.id,
                    fInicio: payload.fechaIn,
                    fCierre: payload.fechaOut,
                    descripcion: payload.descripcion
                });
                state.modificado = true;
            }
            else {
                estado.fInicio = payload.fechaIn;
                estado.fCierre = payload.fechaOut;
                estado.descripcion = payload.descripcion;
                state.modificado = true;
            }
        } else {
            if (estado) {
                var indice = state.item.casoAlerta.indexOf(
                    state.item.casoAlerta.find(
                        x => x.alertaId == payload.alertaId
                    )
                );
                if (indice >= 0) {
                    state.item.casoAlerta.splice(indice, 1);
                    state.modificado = true;
                }
            }
        }
    }
};

export const caso = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
