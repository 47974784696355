import { userService } from '../_services';
import { router } from '../_helpers';

let service = userService;

const state = {
    items: [],
    item: user,
    id: 0,
    loggedIn: user != null,
    progress: false,
    processed: false,
    error: false,
    dialog: false,
    dialogDetalle: false,

    deflt: {
        id: 0,
        accessFailedCount: 0,
        activo: true,
        userName: '',
        apellido: '',
        abrv: '',
        email: '',
        emailConfirmed: false,
        lockoutEnabled: false,
        lockoutEnd: null,
        nombre: '',
        normalizedEmail: '',
        normalizedUserName: '',
        passwordHash: '',
        phoneNumber: '',
        phoneNumberConfirmed: false,
        securityStamp: '',
        concurrencyStamp: '',
        token: '',
        twoFactorEnabled: false,
        userRoles: [],
        userClientes: [],
        //borrado: false,
        //clienteId: null,
        //cliente: { nombre: '', id: null },
        detalles: [],
        detalles1: []
    },
    defltTramite: {
        id: 0
    }
};

const actions = {
    initialize({ dispatch }) {
        dispatch('getAll');
        dispatch('setItem', JSON.parse(JSON.stringify(state.deflt)));
        //dispatch('tramite/getAll', null, { root: true });
    },

    editItem({ dispatch, commit }, itm) {
        if (state.dialog) commit('setDialog', false);

        if (itm === null) {
            dispatch('setItem', JSON.parse(JSON.stringify(state.deflt)));
        } else {
            dispatch('getById', itm.id);
        }
        commit('setDialog', true);
    },

    editDetalle({ dispatch, commit }, itm) {
        itm.nuevoTramite = false;

        dispatch('setDetalle', itm);
        commit('setDialogDetalle', true);
    },

    newDetalle({ dispatch, commit }, itm) {
        //var nt = JSON.parse(JSON.stringify(state.defltTramite));
        //nt.orden = state.item.casoTramite.length;
        //nt.borrado = false;
        //nt.nuevoTramite = true;
        //nt.tramiteId = itm.tramiteId;
        //nt.fInicio = null;
        //nt.fCierre = null;
        //nt.completado = false;
        //nt.usuario = 0;
        dispatch('setDetalle', nt);
        commit('setDialogDetalle', true);
    },

    valida({ state, dispatch, commit }, completa) {
        return true;
    },

    saveItem({ dispatch, commit }) {
        if (state.item.id > 0) {
            dispatch('putObject');
        } else {
            dispatch('postObject');
        }
    },

    setDialog({ commit }, payload) {
        commit('setDialog', payload);
    },

    setDialogDetalle({ commit }, payload) {
        commit('setDialogDetalle', payload);
    },

    setPassword({ commit }, payload) {
        commit('setPassword', payload);
    },

    closeDialog({ commit }) {
        commit('setDialog', false);
    },

    closeDialogDetalle({ commit }) {
        commit('setDialogDetalle', false);
    },

    getAll({ dispatch, commit, state }) {
        commit('getAllRequest');

        service.getAll().then(
            registro => commit('getAllSuccess', registro),
            error => {
                commit('failure', error);
                if (error.response.status == 401) {
                    dispatch(
                        'alert/errorTime',
                        'Error de permisos o sesión expirada!',
                        {
                            root: true
                        }
                    );
                    router.push({ name: 'login' });
                } else {
                    dispatch('alert/errorTime', error.message, {
                        root: true
                    });
                }
            }
        );
    },

    getById({ commit }, id) {
        commit('getByRequest');

        service.getById(id).then(
            registro => commit('getBySuccess', registro),
            error => commit('failure', error)
        );
    },

    //getxyyyy({ commit }, yyyyId) {
    //    commit('getAllRequest');

    //    service
    //        .getxyyyy(yyyyId)
    //        .then(
    //            registro => commit('getAllSuccess', registro),
    //            error => commit('failure', error)
    //        );
    //},

    postObject({ dispatch, commit }) {
        commit('postRequest', state.item);

        return service.postObject(state.item).then(
            registro => {
                commit('postSuccess', registro);
                commit('setDialog', false);
            },
            error =>
                dispatch('alert/errorTime', error.response.data, { root: true })
        );
    },

    putObject({ dispatch, commit }) {
        commit('putRequest', state.item);

        service.putObject(state.item).then(
            registro => {
                commit('putSuccess', registro);
                commit('setDialog', false);
            },
            error =>
                dispatch('alert/errorTime', error.response.data, { root: true })
        );
    },

    deleteObject({ commit }, id) {
        commit('deleteRequest', id);

        service.deleteObject(id).then(
            user => {
                commit('deleteSuccess', id);
                commit('setDialog', false);
            },
            error => commit('failure', { id, error: error.toString() })
        );
    },

    setItem({ commit }, payload) {
        commit('setItem', payload);
    },

    setDetalle({ commit }, payload) {
        commit('setDetalle', payload);
    },

    setDetalle1({ commit }, payload) {
        commit('setDetalle1', payload);
    },

    pushChild({ commit }, payload) {
        commit('pushChild', payload);
    },

    pushChild1({ commit }, payload) {
        commit('pushChild1', payload);
    }
};

const mutations = {
    // GetAll
    getAllRequest(state) {
        state.items = [];
        state.progress = true;
    },

    getAllSuccess(state, registro) {
        state.items = registro;
        state.progress = false;
    },

    failure(state, error) {
        state.id = 0;
        state.progress = false;
    },

    // GetBy
    getByRequest(state) {
        state.item = {};
        state.id = 0;
        state.progress = true;
    },

    getBySuccess(state, registro) {
        state.item = registro;
        state.item.detalles = [];
        state.item.detalles1 = [];
        state.item.userRoles.forEach(i => state.item.detalles.push(i.roleId));
        state.item.userClientes.forEach(i =>
            state.item.detalles1.push(i.clienteId)
        );
        state.id = registro.id;
        state.progress = false;
    },

    // Post
    postRequest(state) {
        state.progress = true;
    },

    postSuccess(state, registro) {
        state.items.push(registro);
        state.item = registro;
        state.progress = false;
    },

    // Put
    putRequest(state) {
        state.progress = true;
    },

    putSuccess(state, registro) {
        var i = state.items.find(x => x.id == registro.id);
        Object.assign(i, registro);

        state.progress = false;
    },

    deleteRequest(state, id) {
        state.progress = true;
        // add 'deleting:true' property to user being deleted
        state.items = state.items.map(user =>
            user.id === id ? { ...user, deleting: true } : user
        );
    },

    deleteSuccess(state, id) {
        // remove deleted user from state
        state.items = state.items.filter(user => user.id !== id);
        state.progress = false;
    },

    setDialog(state, payload) {
        state.dialog = payload;
        if (payload == false) {
            state.item = JSON.parse(JSON.stringify(state.deflt));
        }
    },

    setPassword(state, payload) {
        state.item.passwordHash = payload;
        if (payload == false) {
            state.item = JSON.parse(JSON.stringify(state.deflt));
        }
    },

    setDialogDetalle(state, payload) {
        state.dialogDetalle = payload;
    },

    setItem(state, payload) {
        state.item = payload;
        if (payload.id == 0) {
            state.id = 0;
        }
    },

    setDetalle(state, payload) {
        state.detalles = payload;
        if (payload.id == 0) {
            state.id = 0;
        }
    },

    setDetalle1(state, payload) {
        state.detalles1 = payload;
        if (payload.id == 0) {
            state.id = 0;
        }
    },

    pushChild(state, payload) {
        state.item.detalles.push(payload);
    },

    pushChild1(state, payload) {
        state.item.detalles1.push(payload);
    }
};

export const user = {
    namespaced: true,
    state,
    actions,
    mutations
};
